import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, Row } from '@zendeskgarden/react-grid'

import { arrayNotEmpty, stringNotEmpty } from '@agnostack/lib-core'

import { useQueryParam, useSearch } from '../../../hooks'
import { Heading, SearchBox, Section } from '../../atoms'
import { HeroTitle, Tagline } from '../../../styles/core'
import { GLOBAL_PARAMS } from '../../../util'
import {
  BodyCol,
  HeaderCol,
  PageHeading,
  BodyContainer,
  ContentSection,
  PriorityWell,
  ContentGrid,
  ContentRow,
  SearchItem,
} from './SearchResults.style'

const SearchResults = ({ format, ...renderProps }) => {
  const searchParam = useQueryParam(GLOBAL_PARAMS.SEARCH)

  const { localSearchPages } = useStaticQuery(
    graphql`
      query {
        localSearchPages {
          index
          store
        }
      }
    `
  )

  const { prioritizedResults, results } = useSearch(searchParam, localSearchPages)

  return (
    <>
      <Section mode="thirdscreen" format={format} {...renderProps}>
        <Grid>
          <Row>
            <HeaderCol>
              <PageHeading>
                {!searchParam ? (
                  <HeroTitle>Search</HeroTitle>
                ) : (
                  <>
                    <HeroTitle>Search Results:</HeroTitle>
                    <Tagline>
                      {`"${searchParam}"`}
                    </Tagline>
                  </>
                )}
              </PageHeading>
            </HeaderCol>
          </Row>
        </Grid>
      </Section>
      <ContentSection format="light">
        <ContentGrid id="results">
          <ContentRow>
            <BodyCol md={12} >
              <BodyContainer>
                {!searchParam && (
                  <Heading tag="2">How can we help?</Heading>
                )}
                <SearchBox showSearch />
                {searchParam && (
                  <>
                    {(arrayNotEmpty(prioritizedResults) || arrayNotEmpty(results)) ? (
                      <>
                        {arrayNotEmpty(prioritizedResults) && (
                          <PriorityWell>
                            {prioritizedResults.map(({ id, title, subtitle, path }) => (
                              <SearchItem key={id} data-href={path} highlight>
                                {[title, subtitle].filter(stringNotEmpty).join(' ')}
                              </SearchItem>
                            ))}
                          </PriorityWell>
                        )}
                        {arrayNotEmpty(results) && (
                          <div>
                            {results.slice(-50).map(({ id, title, subtitle, path }) => (
                              <SearchItem key={id} data-href={path}>
                                {[title, subtitle].filter(stringNotEmpty).join(' ')}
                              </SearchItem>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <Heading tag="4">No results found.</Heading>
                    )}
                  </>
                )}
              </BodyContainer>
            </BodyCol>
          </ContentRow>
        </ContentGrid>
      </ContentSection>
    </>
  )
}

export default SearchResults
