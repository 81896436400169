import styled from 'styled-components'
import { navigate } from 'gatsby'

import { Col, Grid, Row } from '@zendeskgarden/react-grid'
import { Well } from '@zendeskgarden/react-notifications'

import { Heading, Section } from '../../atoms'

export const PriorityWell = styled(Well)`
  ${({ theme }) => `
    padding: ${theme.space.xs};
    margin: 0 -${theme.space.xs} ${theme.space.md} -${theme.space.xs};
  `}
`

export const SearchItem = styled.div.attrs(({ 'data-href': path }) => ({
  onClick: () => navigate(path),
}))`
  &:hover {
    cursor: pointer;
  }
  ${({ highlight, theme }) => `
    padding: ${theme.space.sm} ${theme.space.md};
    ${highlight ? `
      background: ${theme.palette?.[theme.colors?.highlightHue]?.[100]};

      &:hover {
        background: ${theme.palette?.[theme.colors?.highlightHue]?.[200]};
      }
    ` : `
      &:hover {
        background: ${theme.palette?.[theme.colors?.neutralHue]?.[100]};
      }
    `}
  `}
`

export const PageHeading = styled(Heading).attrs(() => ({
  tag: '1',
}))`
  flex-grow: 1;
`

export const HeaderCol = styled(Col).attrs(() => ({
  md: 11,
}))`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`

export const BodyContainer = styled.div`
  min-height: 75vh;
`

export const ContentSection = styled(Section)`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.md}) {
      padding-top: 0;
    }
  `}
`

export const ContentGrid = styled(Grid)`
  min-height: 60vh;

  ${({ theme }) => `
    padding-left: 0px;
    padding-right: 0px;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-left: unset;
      padding-right: unset;
    }
  `}
`

export const ContentRow = styled(Row)`
  padding-bottom: 40px;
`

export const BodyCol = styled(Col)`
  align-self: auto;
  margin-left: 2vw;
  margin-top: 8px;
  padding-top: 5em;
  flex-basis: 90%;
  ${({ 'data-sidebar': hasSidebar, theme }) => `
    max-width: ${theme.breakpoints.lg};
    @media (min-width: ${theme.breakpoints.lg}) {
      ${hasSidebar ? `
        flex-basis: 70%;
        max-width: 70%;
      ` : ''}
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      padding-top: 2em;
      margin-left: 0;
      max-width: 100%;
      align-items: center;
    }
  `}
`
