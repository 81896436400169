import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { graphql } from 'gatsby'
import { withTheme } from 'styled-components'

import { hashCode } from '@agnostack/lib-utils-js'

import { TITLE_SEARCH } from '../util'
import { SEO } from '../components/atoms'

import ScheduleModal from '../components/molecules/ScheduleModal'
import SearchResults from '../components/molecules/SearchResults'
import LearnMore from '../components/molecules/LearnMore'

export const query = graphql`
  query searchPageQuery($appVersion: String!) {
    site {
      siteMetadata {
        tagline
      }
    }
    allMdx(filter: { frontmatter: { version: { eq: $appVersion } } }) {
      edges {
        node {
          body
          frontmatter {
            version
            date
            until
            category
            template
            tags
            title
            link
          }
        }
      }
    }
  }
`

export default withTheme(({
  location: {
    pathname,
  } = {},
  data: {
    site: {
      siteMetadata: {
        tagline,
      } = {},
    } = {},
  } = {},
}) => {
  const guid = useMemo(() => (pathname ? `${hashCode(pathname)}-booking` : uuidv4()), [pathname])
  return (
    <>
      <SEO title={`${TITLE_SEARCH}: ${tagline}`} keywords="search" />
      <SearchResults />
      <LearnMore
        id="learn-more"
        mode="overlay"
        max="sm"
        format="light"
      />
      <ScheduleModal id={guid} />
    </>
  )
})
